<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          <p class="mb-0">
            © 2015-{{ new Date().getFullYear() }} ITEM<i>ize</i>-Pro
          </p>
        </div>
        <div class="col-sm-6">
          <div class="text-sm-end d-none d-sm-block">
            Powered By <i class="mdi mdi-heart text-danger"></i> CODEBLOCK LTD
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
